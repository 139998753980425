<template>
  <div class="main" ref="wrapper" tabindex="0" @keyup.esc="$emit('close')">
    <div class="main__content overflow-y-auto">
      <div class="header">
        <h1>{{ $t('tariff.payments-history') }}</h1>
        <i class="el-icon-close cursor-pointer" @click="$emit('close')"></i>
      </div>
      <div class="datePicker">
        <el-date-picker
          type="daterange"
          :placeholder="$t('routesTrips.trip.date.placeholder')"
          range-separator="-"
          value-format="yyyy-MM-dd HH:mm:ss"
          :clearable="false"
          v-model="paymentHistoryDateRange"
          popper-class="datePickerrange"
          @change="handlhistoryDate"
          style="width: 80%"
          :default-value="new Date()"
        ></el-date-picker>
      </div>
      <table class="mt-4 table w-full table-fixed">
        <thead class="w-full">
          <th class="bgwhite.top-0 sticky pl-2">
            <span class="font-bold text-darkblue mr-2">
              {{ $t('tariff.date-time') }}</span
            >
          </th>
          <th class="bgwhite.top-0 sticky pl-2">
            <span class="font-bold text-darkblue mr-2">
              {{ $t('tariff.operation') }}</span
            >
          </th>
          <th class="bgwhite.top-0 sticky pl-2">
            <span class="font-bold text-darkblue mr-2">
              {{ $t('tariff.amount') }}</span
            >
          </th>
          <th class="bgwhite.top-0 sticky pl-2">
            <span class="font-bold text-darkblue mr-2">
              {{ $t('tariff.objects') }}</span
            >
          </th>
        </thead>
        <tbody style="border-top: 4px solid white">
          <tr
            class="hover_bg-reallylightblue border-b"
            v-for="(payment, i) in transactions"
            :key="i"
          >
            <td class="ellipsis pl-2 time">
              <span>
                {{ payment.timestamp }}
              </span>
            </td>
            <td class="ellipsis pl-2">
              <div class="operation w-full">
                <span :class="payment.amount > 0 ? 'green' : 'red'">{{
                  payment.amount
                }}</span>
                <span>
                  {{ payment.payment_type ? payment.payment_type : '' }}</span
                >
              </div>
            </td>
            <td class="ellipsis pl-2 text-center">
              <span class="leading-10 text-lightblue">
                {{ payment.amount_after }} {{ payment.currency.display_name }}
              </span>
            </td>
            <td class="ellipsis pl-2 text-center">
              <span class="leading-10 text-lightblue">
                {{ payment.units }}
              </span>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>
<script>
import { mapState } from 'vuex'

import moment from 'moment'

export default {
  data() {
    return {
      paymentHistoryDateRange: [
        moment(new Date()).subtract(1, 'months').format('YYYY-MM-DD HH:mm:ss'),
        moment(new Date()).format('YYYY-MM-DD HH:mm:ss')
      ]
    }
  },
  computed: {
    ...mapState('billing', {
      order: (state) => state.order,
      transactions: (state) => state.transactions
    })
  },
  methods: {
    handlhistoryDate() {
      const formData = {
        max_rows: 100,
        first_row: 0,
        from: this.paymentHistoryDateRange[0],
        to: this.paymentHistoryDateRange[1]
      }
      this.$store.dispatch('billing/GET_TRANSACTIONS', formData)
    }
  },
  created() {
    this.handlhistoryDate()
  },
  mounted() {
    this.$refs.wrapper.focus()
    this.$refs.wrapper.style.outline = 'none'
  }
}
</script>

<style scoped lang="scss">
.main {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: #424242a1;
  width: 100vw;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 14px;
  &__content {
    background: #ffffff;
    width: 50%;
    height: 500px;
    padding: 16px;
    .header {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-bottom: 16px;
      h1 {
        color: #5477a9;
        font-weight: 700;
        font-size: 18px;
      }
    }
  }
  .datePicker {
    margin-bottom: 16px;
    display: flex;
    justify-content: center;
  }
  .time {
    font-size: 14px;
    color: #5477a9;
    line-height: 2.5rem;
    font-weight: bold;
  }
  .operation {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    .green {
      color: #4abc96;
    }
    .red {
      color: #f56c6c;
    }
  }
}
.reportsDatepicker {
  z-index: 99999;
}
</style>
